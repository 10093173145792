import React, { createContext, useReducer } from 'react';
import { PropTypes } from 'prop-types';

const CodesContext = createContext(null);

const initialState = {
  loaded: false,
  ibc: [],
  irc: [],
  iebc: [],
  asce: [],
  electricalCode: [],
  fireCode: []
};

export const CODES_TYPES = {
  LOADED: 'CODES/LOADED',
  UPDATE_IBC_CODES: 'CODES/UPDATE_IBC_CODES',
  UPDATE_IEBC_CODES: 'CODES/UPDATE_IEBC_CODES'
};

const updateExistingCode = (updatedCode, existingData) => {
  return existingData.map(code => {
    if (code.id === updatedCode.id) {
      return updatedCode;
    }
    return code;
  });
};

const reducer = (state, action) => {
  switch (action.type) {
    case CODES_TYPES.LOADED:
      return {
        ...state,
        loaded: true,
        ibc: [...action.payload.ibc],
        irc: [...action.payload.ibc],
        iebc: [...action.payload.iebc],
        asce: [...action.payload.asce],
        electricalCode: [...action.payload.electricalCode],
        fireCode: [...action.payload.fireCode]
      };
    case CODES_TYPES.UPDATE_IBC_CODES:
      return {
        ...state,
        ibc: action.payload.newCode
          ? [...action.payload.newCode]
          : updateExistingCode(action.payload.updatedCode, state.ibc)
      };
    case CODES_TYPES.UPDATE_IEBC_CODES:
      return {
        ...state,
        iebc: action.payload.newCode
          ? [...action.payload.newCode]
          : updateExistingCode(action.payload.updatedCode, state.iebc)
      };
    default:
      return state;
  }
};

const CodesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <CodesContext.Provider value={value}>{children}</CodesContext.Provider>
  );
};

CodesProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { CodesContext, CodesProvider };
