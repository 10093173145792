import { v4 as uuidv4 } from 'uuid';
import { uploadToApi } from '../Api';

function uploadFile({
  url,
  file,
  events = {},
  ahjs = [],
  isVerification = false
}) {
  const { onUploadStarted, onUploadProgress, onUploadError, onUploadFinished } =
    events;
  const fileIdentifier = uuidv4();
  onUploadStarted({
    id: fileIdentifier,
    name: file.name
  });
  async function uploadProcess() {
    let result;
    try {
      result = (
        await uploadToApi({
          url,
          file,
          onUploadProgress: ({ loaded, total }) => {
            onUploadProgress({
              id: fileIdentifier,
              progress: (100.0 * loaded) / total
            });
          },
          ahjs,
          isVerification
        })
      ).data;
    } catch (exception) {
      console.error('Unable to upload file: ', exception);
      onUploadError({
        id: fileIdentifier,
        error: exception,
        name: file.name
      });
    }
    onUploadFinished({
      id: fileIdentifier,
      name: file.name,
      file,
      result
    });
    return result;
  }
  return uploadProcess();
}

export default uploadFile;
