import React, { lazy, Suspense } from 'react';
import { Routes as ReactRoutes, Route } from 'react-router';

import RequireUnauthenticated from './routes/Redirects/RequireUnauthenticated';
import RequireAuthenticated from './routes/Redirects/RequireAuthenticated';
import LoadingContainer from './shared/containers/Loading';

const AsyncHome = lazy(() => import('./routes/Home'));
const AsyncPermitDesign = lazy(() => import('./routes/Home/PermitDesign'));
const AsyncEngineering = lazy(() => import('./routes/Home/Engineering'));
const AsyncStructuralEng = lazy(() => import('./routes/Home/StructuralEng'));
const AsyncElectricalEng = lazy(() => import('./routes/Home/ElectricalEng'));
const AsyncAbout = lazy(() => import('./routes/Home/AboutUs'));
const AsyncBlog = lazy(() => import('./routes/Home/Blog'));
const AsyncBlogPost = lazy(() => import('./routes/Home/Blog/BlogPost'));
const AsyncContact = lazy(() => import('./routes/Home/Contact'));
const AsyncPrivacy = lazy(() => import('./routes/Home/Privacy'));
const AsyncTerms = lazy(() => import('./routes/Home/Terms'));
const AsyncLogin = lazy(() => import('./routes/Actions/Login'));
const AsyncRequestDemo = lazy(() => import('./routes/Actions/RequestDemo'));
const AsyncForgotPassword = lazy(
  () => import('./routes/Actions/ForgotPassword')
);
const AsyncVerifyEmail = lazy(() => import('./routes/Actions/VerifyEmail'));
const AsyncDashboard = lazy(() => import('./routes/Dashboard'));

// Unused home pages, might use one day if we want to expand the content on our homepages
// const AsyncServices = lazy(() => import('./routes/Home/Services'));
// const AsyncPlatform = lazy(() => import('./routes/Home/Platform'));
// const AsyncCustomers = lazy(() => import('./routes/Home/Customers'));

const Routes = () => {
  return (
    <Suspense fallback={<LoadingContainer />}>
      <ReactRoutes>
        <Route
          path="login"
          element={
            <RequireUnauthenticated>
              <AsyncLogin />
            </RequireUnauthenticated>
          }
        />
        <Route
          path="request-demo"
          element={
            <RequireUnauthenticated>
              <AsyncRequestDemo />
            </RequireUnauthenticated>
          }
        />
        <Route
          path="password-reset/:uid/:token"
          element={<AsyncForgotPassword />}
        />

        <Route path="password-reset" element={<AsyncForgotPassword />} />
        <Route path="verify-email/:key" element={<AsyncVerifyEmail />} />
        <Route path="permit-design" element={<AsyncPermitDesign />} />
        <Route path="engineering" element={<AsyncEngineering />} />
        <Route path="structural-engineering" element={<AsyncStructuralEng />} />
        <Route path="electrical-engineering" element={<AsyncElectricalEng />} />
        <Route path="about" element={<AsyncAbout />} />
        <Route path="blog/:postId" element={<AsyncBlogPost />} />
        <Route path="blog" element={<AsyncBlog />} />
        <Route path="contact-us" element={<AsyncContact />} />
        <Route path="terms" element={<AsyncTerms />} />
        <Route path="privacy-policy" element={<AsyncPrivacy />} />
        <Route path="home/redirect/:shouldClose" element={<AsyncHome />} />
        <Route path="home" element={<AsyncHome />} />

        <Route
          path="/"
          element={
            <RequireUnauthenticated>
              <AsyncHome />
            </RequireUnauthenticated>
          }
        />

        <Route
          path="*"
          element={
            <RequireAuthenticated>
              <AsyncDashboard />
            </RequireAuthenticated>
          }
        />
      </ReactRoutes>
    </Suspense>
  );
};

export default Routes;
