import React, { createContext, useReducer } from 'react';
import { PropTypes } from 'prop-types';

const JobsContext = createContext(null);

const initialState = {
  companies: [],
  isLoadingOptions: false,
  connectionTypes: [],
  structureTypes: [],
  gypsumTypes: [],
  roofMaterials: [],
  materialGrades: [],
  beamShapes: [],
  rackingTypes: [],
  moduleTypes: [],
  optimizerTypes: [],
  inverterTypes: [],
  batteryTypes: [],
  mciTypes: [],
  acDisconnectTypes: [],
  productionMeterTypes: [],
  states: [],
  selectedJob: null
};

export const JOBS_TYPES = {
  SET_IS_LOADING_OPTIONS: 'JOBS/SET_IS_LOADING_OPTIONS',
  LOADED: 'JOBS/LOADED',
  UPDATE_COMPANIES: 'JOBS/UPDATE_COMPANIES',
  UPDATE_COMPANY: 'JOBS/UPDATE_COMPANY',
  UPDATE_SELECTED_JOB: 'JOBS/UPDATE_SELECTED_JOB',
  UPDATE_MATERIAL: 'JOBS/UPDATE_MATERIAL'
};

const reducer = (state, action) => {
  switch (action.type) {
    case JOBS_TYPES.SET_IS_LOADING_OPTIONS:
      return {
        ...state,
        isLoadingOptions: action.payload
      };
    case JOBS_TYPES.LOADED:
      return {
        ...state,
        isLoadingOptions: false,
        ...(action.payload.companies &&
          action.payload.companies.length && {
            companies: [...action.payload.companies]
          }),
        ...(action.payload.connectionTypes &&
          action.payload.connectionTypes.length && {
            connectionTypes: [...action.payload.connectionTypes]
          }),
        ...(action.payload.structureTypes &&
          action.payload.structureTypes.length && {
            structureTypes: [...action.payload.structureTypes]
          }),
        ...(action.payload.gypsumTypes &&
          action.payload.gypsumTypes.length && {
            gypsumTypes: [...action.payload.gypsumTypes]
          }),
        ...(action.payload.roofMaterials &&
          action.payload.roofMaterials.length && {
            roofMaterials: [...action.payload.roofMaterials]
          }),
        ...(action.payload.materialGrades &&
          action.payload.materialGrades.length && {
            materialGrades: [...action.payload.materialGrades]
          }),
        ...(action.payload.beamShapes &&
          action.payload.beamShapes.length && {
            beamShapes: [...action.payload.beamShapes]
          }),
        ...(action.payload.rackingTypes &&
          action.payload.rackingTypes.length && {
            rackingTypes: [...action.payload.rackingTypes]
          }),
        ...(action.payload.moduleTypes &&
          action.payload.moduleTypes.length && {
            moduleTypes: [...action.payload.moduleTypes]
          }),
        ...(action.payload.optimizerTypes &&
          action.payload.optimizerTypes.length && {
            optimizerTypes: [...action.payload.optimizerTypes]
          }),
        ...(action.payload.inverterTypes &&
          action.payload.inverterTypes.length && {
            inverterTypes: [...action.payload.inverterTypes]
          }),
        ...(action.payload.batteryTypes &&
          action.payload.batteryTypes.length && {
            batteryTypes: [...action.payload.batteryTypes]
          }),
        ...(action.payload.mciTypes &&
          action.payload.mciTypes.length && {
            mciTypes: [...action.payload.mciTypes]
          }),
        ...(action.payload.acDisconnectTypes &&
          action.payload.acDisconnectTypes.length && {
            acDisconnectTypes: [...action.payload.acDisconnectTypes]
          }),
        ...(action.payload.productionMeterTypes &&
          action.payload.productionMeterTypes.length && {
            productionMeterTypes: [...action.payload.productionMeterTypes]
          }),
        ...(action.payload.states &&
          action.payload.states.length && {
            states: [...action.payload.states]
          })
      };
    case JOBS_TYPES.UPDATE_MATERIAL:
      const { materialType, material } = action.payload;
      if (!state[materialType]) {
        console.warn(`Material type ${materialType} does not exist in state`);
        return state;
      }
      return {
        ...state,
        [materialType]: state[materialType]
          .map(item => (item.id === material.id ? material : item))
          .concat(
            state[materialType].find(item => item.id === material.id)
              ? []
              : [material]
          )
      };
    case JOBS_TYPES.UPDATE_COMPANIES:
      return {
        ...state,
        companies: [...action.payload.companies]
      };
    case JOBS_TYPES.UPDATE_SELECTED_JOB:
      return {
        ...state,
        selectedJob: action.payload.job
      };
    default:
      return state;
  }
};

const JobsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return <JobsContext.Provider value={value}>{children}</JobsContext.Provider>;
};

JobsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { JobsContext, JobsProvider };
