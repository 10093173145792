const components = (palette, customShadows) => {
  return {
    // Typography
    MuiTypography: {
      styleOverrides: {
        paragraph: {
          marginBottom: '16px'
        },
        gutterBottom: {
          marginBottom: '8px'
        }
      }
    },
    // Lists
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 'auto',
          marginRight: '16px' // theme.spacing(2)
        }
      }
    },
    // Steps
    MuiStepLabel: {
      styleOverrides: {
        label: {
          '&.Mui-completed': {
            color: palette.text.secondary,
            fontWeight: 'normal'
          },
          '&.Mui-active': {
            fontWeight: 600,
            color: palette.text.primary
          },
          '&.Mui-error': {
            color: palette.error.dark
          }
        }
      }
    },
    // Paper
    MuiPaper: {
      defaultProps: {
        elevation: 0
      },

      styleOverrides: {
        root: {
          backgroundImage: 'none'
        }
      }
    },
    // Card
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: customShadows.z4,
          borderRadius: '16px',
          position: 'relative',
          zIndex: 0 // Fix Safari overflow: hidden with border radius
        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h6' },
        subheaderTypographyProps: { variant: 'body2' }
      },
      styleOverrides: {
        root: {
          padding: '16px 16px 0'
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px'
        }
      }
    },
    // IconButton
    MuiIconButton: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: { color: 'default' },
              style: {
                '&:hover': { backgroundColor: palette.action.hover }
              }
            },
            {
              props: { color: 'inherit' },
              style: {
                '&:hover': { backgroundColor: palette.action.hover }
              }
            }
          ]
        }
      }
    },
    // Inputs
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            '& svg': {
              color: palette.text.disabled
            }
          }
        },
        input: {
          '&::placeholder': {
            opacity: 1,
            color: palette.text.disabled
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottomColor: palette.grey[500_56]
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.grey[500_32]
          },
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: palette.grey[500_56]
            }
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: palette.primary.main
            }
          },
          '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: palette.error.main
            }
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: palette.action.disabledBackground
            }
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          boxShadow: customShadows.z20
        }
      }
    },
    // Buttons
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(['outlined', 'text'].includes(ownerState.variant) &&
            ownerState.color === 'primary' &&
            palette.mode === 'light' && {
              color: palette[ownerState.color].dark
            }),
          '&:hover': {
            boxShadow: 'none'
          }
        }),
        sizeLarge: {
          height: 48
        },
        containedInherit: {
          color: palette.grey[800],
          boxShadow: customShadows.z8,
          '&:hover': {
            backgroundColor: palette.grey[400]
          }
        },
        containedPrimary: {
          boxShadow: customShadows.primary
        },
        containedSecondary: {
          boxShadow: customShadows.secondary
        },
        outlinedInherit: {
          border: `1px solid ${palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: palette.action.hover
          }
        },
        textInherit: {
          '&:hover': {
            backgroundColor: palette.action.hover
          }
        },
        text: {
          letterSpacing: 'normal'
        }
      }
    },
    // Misc
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: palette.grey[800]
        },
        arrow: {
          color: palette.grey[800]
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'filled' &&
            !['default', 'primary'].includes(ownerState.color) && {
              backgroundColor: palette[ownerState.color].light
            }),
          borderRadius: '14px',
          fontWeight: '600',
          fontSize: '0.795rem',
          minWidth: 22
        })
      }
    },
    // Dropzone
    MuiDropzoneArea: {
      styleOverrides: {
        root: {
          width: 'auto',
          minHeight: 'auto',
          padding: '40px 0',
          margin: '0',
          borderRadius: '8px',
          border: `1px dashed ${palette.grey[500_32]}`,
          backgroundColor: palette.background.neutral
        },
        textContainer: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'left',
          flexDirection: 'row'
        },
        text: {
          fontWeight: 700,
          fontSize: '0.975rem',
          color: palette.text.secondary,
          lineHeight: '1.5',
          marginRight: '16px',
          marginTop: '0px',
          marginBottom: '0px'
        },
        icon: {
          width: '52px',
          height: '52px'
        }
      }
    },
    MuiDropzonePreviewList: {
      styleOverrides: {
        root: {
          marginTop: '8px'
        }
      }
    }
  };
};

export default components;
