/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { SvgIcon } from '@mui/material';

import Add from '../../assets/plus.svg';
import Checkmark from '../../assets/checkmark.svg';
import Filter from '../../assets/filter.svg';
import Refresh from '../../assets/refresh.svg';
import Minus from '../../assets/minus.svg';
import Save from '../../assets/save-all.svg';
import DeleteTrash from '../../assets/delete-trashbin.svg';
import Delete from '../../assets/delete-no-bg.svg';
import Edit from '../../assets/edit.svg';
import Search from '../../assets/search.svg';
import UpArrow from '../../assets/up-arrow.svg';
import LeftArrow from '../../assets/left-arrow.svg';
import RightArrow from '../../assets/right-arrow.svg';
import FirstArrow from '../../assets/first-arrow.svg';
import LastArrow from '../../assets/last-arrow.svg';
import ViewColumn from '../../assets/view-column.svg';

import Rafter from '../../assets/trusses/rafter@3x.png';
import Purlin from '../../assets/trusses/purlin@3x.png';
import Kingpost from '../../assets/trusses/kingpost@3x.png';
import CollarTie from '../../assets/trusses/collartie@3x.png';
import DoubleFink from '../../assets/trusses/doublefink@3x.png';
import SimpleFink from '../../assets/trusses/simplefink@3x.png';
import DoubleHowe from '../../assets/trusses/doublehowe@3x.png';
import Fan from '../../assets/trusses/fan@3x.png';
import Fink from '../../assets/trusses/fink@3x.png';
import Howe from '../../assets/trusses/howe@3x.png';
import ModifiedQueen from '../../assets/trusses/modifiedqueen@3x.png';
import Queen from '../../assets/trusses/queen@3x.png';
import RafterWhite from '../../assets/trusses/white/rafter@3x.png';
import PurlinWhite from '../../assets/trusses/white/purlin@3x.png';
import KingpostWhite from '../../assets/trusses/white/kingpost@3x.png';
import CollarTieWhite from '../../assets/trusses/white/collartie@3x.png';
import DoubleFinkWhite from '../../assets/trusses/white/doublefink@3x.png';
import SimpleFinkWhite from '../../assets/trusses/white/simplefink@3x.png';
import DoubleHoweWhite from '../../assets/trusses/white/doublehowe@3x.png';
import FanWhite from '../../assets/trusses/white/fan@3x.png';
import FinkWhite from '../../assets/trusses/white/fink@3x.png';
import HoweWhite from '../../assets/trusses/white/howe@3x.png';
import ModifiedQueenWhite from '../../assets/trusses/white/modifiedqueen@3x.png';
import QueenWhite from '../../assets/trusses/white/queen@3x.png';

export const DRAWER_WIDTH = 280;
export const MINI_DRAWER_WIDTH = 80;
export const APPBAR_MOBILE = 64;
export const APPBAR_DESKTOP = 92;

export const IMAGE_EXTENSIONS = [
  'JPEG',
  'JPG',
  'PNG',
  'GIF',
  'TIFF',
  'TIF',
  'HEIC',
  'HEIF'
  // Remove videos from image gallery because Safari autoplays videos wrapped in img tags
  // 'MP4',
  // 'MOV',
  // 'AVI',
  // 'MPEG'
];

// *** Begin Websocket-related events
export const JOBS_TABLE_EVENTS = [
  // Temporarily disable all websocket events (except new message) on the Jobs table to reduce frequency of the table refreshing
  // 'created',
  // 'edited',
  // 'submitted',
  // 'status_changed',
  // 'revised',
  'new_message'
];
export const STATUS_CHANGES = [
  'calculations_initiated',
  'calculations_failed',
  'calculations_passed'
];
export const FILE_STATUS_CHANGES = ['uploaded', 'failed', 'uploads_finished'];
// *** End Websocket-related events

export const SERVICE_NAMES = [
  { key: 'needs_structural_letter', name: 'Structural Roof Letter' },
  { key: 'needs_structural_stamp', name: 'CAD Stamp (Structural)' },
  { key: 'needs_electrical_stamp', name: 'CAD Stamp (Electrical)' },
  { key: 'needs_ground_mount_letter', name: 'Structural Ground Mount Letter' },
  { key: 'needs_cad_packet', name: 'CAD Design' },
  { key: 'needs_post_install_letter', name: 'Post-Install Letter' },
  { key: 'needs_wall_mount_battery', name: 'Wall Mounted Battery Letter' },
  { key: 'needs_load_calculations', name: 'Electrical Load Calculations' },
  { key: 'needs_non_pv_design', name: 'Non-PV Design' },
  { key: 'needs_shade_report', name: 'Shade Report' }
];

export const SERVICE_NAMES_LINE_ITEMS = SERVICE_NAMES.map(item => item.name)
  .concat(['CAD/ENG'])
  .concat(
    SERVICE_NAMES.filter(item => item.key !== 'needs_post_install_letter').map(
      item => `${item.name} (Revision)`
    )
  )
  .concat(['Wet Stamp', 'Wet Affidavit', 'Other']);

export const REVISION_REASONS = [
  { value: 'equipment_change', name: 'Equipment Change' },
  { value: 'electrical_change', name: 'Electrical Change' },
  { value: 'ahj_rejection', name: 'AHJ Rejection' },
  { value: 'utility_rejection', name: 'Utility Rejection' },
  { value: 'as_built', name: 'As-Built' },
  { value: 'company_preference', name: 'Company Preference' },
  { value: 'right_angle_error', name: 'Right Angle Error' },
  { value: 'layout_change', name: 'Layout Change' },
  { value: 'additional_service', name: 'Additional Service' },
  { value: 'other', name: 'Other' }
];

export const EVENT_SUBTYPES = {
  'Job Actions': {
    created: 'Created',
    submitted: 'Submitted',
    edited: 'Edited',
    draft: 'Drafted',
    approved: 'Approved',
    assigned: 'Assigned',
    unassigned: 'Unassigned',
    cancelled: 'Cancelled',
    revision: 'Revision',
    deactivated: 'Deactivated'
  },
  'Status Changes': {
    pending_tier_1: 'Pending Tier 1',
    pending_tier_2: 'Pending Tier 1',
    pending_drawing: 'Pending Drawing',
    pending_qc: 'Pending QC',
    qc_rejection: 'QC Rejection',
    pending_design: 'Pending Design',
    pending_design_revision: 'Pending Design Revision',
    waiting_on_ahj: 'Waiting on AHJ',
    pending_client: 'Pending Client',
    pending_pe: 'Pending PE',
    pending_ee: 'Pending EE',
    design_completed: 'Design Completed',
    pending_approval: 'Pending Approval',
    finalized: 'Finalized',
    pending_rae: 'Pending RAE'
  },
  'File Deletions': {
    letter: 'Deleted Letter',
    client_upload: 'Deleted Client Uploaded File',
    cad: 'Deleted CAD File',
    internal: 'Deleted Internal File',
    cad_intake_form: 'Deleted CAD Intake Form',
    misc_finished: 'Deleted Finished File'
  }
};

export const CHART_INTERVALS = [
  'All',
  'Daily',
  'Weekly',
  'Biweekly',
  'Monthly',
  'Quarterly',
  'Biannually',
  'Yearly'
];

export const EMBEDMENT_TYPES = [
  'Roof Metal Sheeting',
  'Roof Sheathing',
  'Roof Substructure',
  'Roof Material',
  '30 ga Steel',
  '29 ga Steel',
  '28 ga Steel',
  '26 ga Steel',
  '24 ga Steel',
  '22 ga Steel',
  '20 ga Steel',
  '18 ga Steel',
  '16 ga Steel',
  '14 ga Steel',
  '12 ga Steel',
  '10 ga Steel',
  '8 ga Steel',
  '6 ga Steel',
  '3/8" OSB',
  '7/16" OSB',
  '1/2" OSB',
  '9/16" OSB',
  '5/8" OSB',
  '3/8" Plywood',
  '7/16" Plywood',
  '1/2" Plywood',
  '9/16" Plywood',
  '5/8" Plywood',
  '1mm Aluminum',
  '.8mm Aluminum',
  '.7mm Aluminum',
  '.05" Aluminum',
  '.04" Aluminum',
  '.032" Aluminum',
  '.027" Aluminum',
  '1mm Zinc',
  '.8mm Zinc',
  '.7mm Zinc',
  '16oz Copper',
  '.7mm Copper',
  '.9mm BMT',
  'Ballast',
  'Tesla Connection'
];
export const ENCLOSURE_TYPES = ['Enclosed', 'Partially Enclosed', 'Open'];
export const GYPSUM_TYPES = [
  { id: '00000000000000000000000000000001', name: '1/2"' },
  { id: '00000000000000000000000000000002', name: '1/4"' },
  { id: '00000000000000000000000000000003', name: '3/8"' },
  { id: '00000000000000000000000000000004', name: '5/8"' },
  { id: '00000000000000000000000000000005', name: 'Lath and Plaster' },
  { id: '00000000000000000000000000000006', name: 'No Drywall' }
];
export const STATUS = ['Draft', 'Submitted', 'Approved', 'Cancelled'];
export const INTERNAL_STATUS = [
  'Pending Drawing',
  'Pending QC',
  'Pending Design',
  'Pending Design Revision',
  'Pending PE',
  'Pending EE',
  'Pending Client',
  'Design Completed',
  'Finalized',
  'Pending Approval',
  'Pending Electrical Check',
  'QC Rejection',
  'Waiting on AHJ'
];

// export const CALC_METHODS = ['Prescriptive', 'Forte', 'SkyCiv'];
export const CALC_METHODS = [
  { name: 'Prescriptive Method (5%)', value: 'Prescriptive Method (5%)' },
  { name: 'Forte', value: 'Forte' },
  { name: 'SkyCiv', value: 'SkyCiv' }
];

export const TRUE_OR_FALSE = ['True', 'False'];
export const ORIENTATION = ['Portrait', 'Landscape'];
export const ROOF_TYPES = ['Gable Roof', 'Hip Roof'];
export const EXPOSURE_CATEGORIES = ['B', 'C', 'D'];
export const AHJ_SEISMIC_CATEGORIES = [
  'A',
  'B',
  'C',
  'D',
  'D0',
  'D1',
  'D2',
  'E'
];
export const AHJ_SITE_CLASSES = ['A', 'B', 'C', 'D', 'E', 'F'];
export const AHJ_REGIONAL_TYPES = ['City', 'County', 'Region', 'Other'];
export const VERIFICATION_OPTIONS = ['Verified', 'Unverified/Expired'];

export const MIN_MSP_RATING = 100;
export const MAX_MSP_RATING = 1600;
export const BATTERY_BACKUP_TYPES = [
  'Non-Backup',
  'Whole Home',
  'Partial Home'
];
export const INTERNAL_INTERCONNECTION_OPTIONS = [
  'Breaker Injection into MSP',
  'Breaker in MSP Center Feed',
  'Breaker in MSP Top Feed',
  'Supply Side Tap',
  'Supply Side Tap in Meter Can',
  'Supply Side Tap in Tap Box',
  'Supply Side Tap No EGC',
  'Supply Side Tap In Meter Can No EGC',
  'Supply Side Tap In Tap Box No EGC',
  'Supply Side Tap No EGC Neutral Included',
  'Supply Side Tap In Meter Can No EGC Neutral Included',
  'Supply Side Tap In Tap Box No EGC Neutral Included',
  'Load Side Tap',
  'Load Side Tap New Sub Panel',
  'Load Side Tap New Fusible Disconnect',
  'GMA',
  'Breaker in MLO MSP',
  'Breaker in Line Side Slot',
  'Breaker in Sub Panel',
  'Sub Panel Re-Route',
  'Sub Panel Re-Route 2 Sub Panels',
  'Sub Panel Re-Route 1',
  'Sub Panel Re-Route 2',
  'Line Side Tap Box',
  'Fusible Disconnect Add',
  'Hawaiian Tie In',
  'Meter Adapter'
];
export const CLIENT_INTERCONNECTION_OPTIONS = [
  'Breaker Injection into MSP',
  'Supply Side Tap',
  'Load Side Tap'
];
export const PRODUCTION_METER_LOCATIONS = [
  'Not Applicable',
  'Between Solar and Disconnect',
  'Between Disconnect and MSP'
];
export const PREFERRED_RACKING_MANUFACTURER_OPTIONS = [
  'Ironridge (recommended)',
  'Unirac',
  'SnapNrack',
  'Other (specified in Job notes)'
];
export const FOUNDATION_TYPE_OPTIONS = ['Concrete Foundation', 'Ground Screw'];
export const WIRE_SIZES = [
  '12 AWG',
  '10 AWG',
  '8 AWG',
  '6 AWG',
  '4 AWG',
  '3 AWG',
  '2 AWG',
  '1/0 AWG',
  '2/0 AWG',
  '3/0 AWG',
  '4/0 AWG'
];

export const FRAME_SHAPES = [
  {
    name: 'Non Standard',
    members: 0,
    hasKingPost: true,
    isTruss: true,
    imageUrl: '',
    whiteImageUrl: ''
  },
  {
    name: 'Rafter',
    members: 1,
    hasKingPost: false,
    isTruss: false,
    imageUrl: `url(${Rafter})`,
    whiteImageUrl: `url(${RafterWhite})`
  },
  // half of these standard trusses have a central post, let's call it a "kingpost"
  {
    name: 'Kingpost',
    members: 1,
    hasKingPost: true,
    isTruss: true,
    imageUrl: `url(${Kingpost})`,
    whiteImageUrl: `url(${KingpostWhite})`
  },
  {
    name: 'Collar Tie',
    members: 1,
    hasKingPost: false,
    isTruss: true,
    imageUrl: `url(${CollarTie})`,
    whiteImageUrl: `url(${CollarTieWhite})`
  },
  {
    name: 'Simple Fink',
    members: 2,
    hasKingPost: false,
    isTruss: true,
    imageUrl: `url(${SimpleFink})`,
    whiteImageUrl: `url(${SimpleFinkWhite})`
  },
  {
    name: 'Queen',
    members: 3,
    hasKingPost: true,
    isTruss: true,
    imageUrl: `url(${Queen})`,
    whiteImageUrl: `url(${QueenWhite})`
  },
  {
    name: 'Fink',
    members: 4,
    hasKingPost: false,
    isTruss: true,
    imageUrl: `url(${Fink})`,
    whiteImageUrl: `url(${FinkWhite})`
  },
  {
    name: 'Howe',
    members: 5,
    hasKingPost: true,
    isTruss: true,
    imageUrl: `url(${Howe})`,
    whiteImageUrl: `url(${HoweWhite})`
  },
  {
    name: 'Fan',
    members: 6,
    hasKingPost: false,
    isTruss: true,
    imageUrl: `url(${Fan})`,
    whiteImageUrl: `url(${FanWhite})`
  },
  {
    name: 'Modified Queen',
    members: 7,
    hasKingPost: true,
    isTruss: true,
    imageUrl: `url(${ModifiedQueen})`,
    whiteImageUrl: `url(${ModifiedQueenWhite})`
  },
  {
    name: 'Double Fink',
    members: 8,
    hasKingPost: false,
    isTruss: true,
    imageUrl: `url(${DoubleFink})`,
    whiteImageUrl: `url(${DoubleFinkWhite})`
  },
  {
    name: 'Double Howe',
    members: 9,
    hasKingPost: true,
    isTruss: true,
    imageUrl: `url(${DoubleHowe})`,
    whiteImageUrl: `url(${DoubleHoweWhite})`
  },
  {
    name: 'Purlin',
    members: 1,
    hasKingPost: false,
    isTruss: false,
    imageUrl: `url(${Purlin})`,
    whiteImageUrl: `url(${PurlinWhite})`
  }
];

export const ROLES = [
  { name: 'Admin', value: 'admin' },
  { name: 'Support', value: 'support' },
  { name: 'User', value: 'user' },
  { name: 'Company Admin', value: 'user_admin' },
  { name: 'Contractor', value: 'contractor' }
];
export const ALL_ROLES = [
  'admin',
  'support',
  'user',
  'user_admin',
  'contractor'
];
export const INTERNAL_ROLES = ['admin', 'support'];

export const TABLE_ICONS = {
  Add: forwardRef((props, ref) => (
    <SvgIcon component={Add} {...props} ref={ref} />
  )),
  Check: forwardRef((props, ref) => (
    <SvgIcon component={Checkmark} {...props} ref={ref} />
  )),
  Clear: forwardRef((props, ref) => (
    <SvgIcon component={Delete} {...props} ref={ref} />
  )),
  Delete: forwardRef((props, ref) => (
    <SvgIcon component={DeleteTrash} {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <SvgIcon component={RightArrow} {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => (
    <SvgIcon component={Edit} {...props} ref={ref} />
  )),
  Export: forwardRef((props, ref) => (
    <SvgIcon component={Save} color="action" {...props} ref={ref} />
  )),
  Filter: forwardRef((props, ref) => <SvgIcon component={Filter} {...props} />),
  FirstPage: forwardRef((props, ref) => (
    <SvgIcon component={FirstArrow} {...props} ref={ref} />
  )),
  LastPage: forwardRef((props, ref) => (
    <SvgIcon component={LastArrow} {...props} ref={ref} />
  )),
  NextPage: forwardRef((props, ref) => (
    <SvgIcon component={RightArrow} {...props} ref={ref} />
  )),
  PreviousPage: forwardRef((props, ref) => (
    <SvgIcon component={LeftArrow} {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => (
    <SvgIcon component={Delete} {...props} ref={ref} />
  )),
  Search: forwardRef((props, ref) => <SvgIcon component={Search} {...props} />),
  SortArrow: forwardRef((props, ref) => (
    <SvgIcon component={UpArrow} {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => (
    <SvgIcon component={Minus} {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props, ref) => (
    <SvgIcon component={ViewColumn} color="action" {...props} ref={ref} />
  )),
  Retry: forwardRef((props, ref) => (
    <SvgIcon component={Refresh} {...props} ref={ref} />
  ))
};

export const DEFAULT_ARRAY_DATA = {
  panel_quantity: '',
  roof_material: '',
  connection_type: '',
  custom_connection_type: null,
  beam_spacing: 24,
  num_panels_on_rafter: '',
  height_from_ground: 20,
  panel_to_eave: 0,
  orientation: ORIENTATION[0],
  frame_shape: '',
  nonstandard_framing: false,
  custom_beam_shape: null,
  roof_pitch: 26,
  roof_overhang: 18,
  ballast_weight: 0,
  module_type: '',
  racking_type: '',
  custom_racking_type: null,
  beam_span: [168],
  beam_shape: '',
  collar_tie: 30,
  truss_builder_coords: {}
};

export const DEFAULT_JOB_FORM_DATA = {
  name: '',
  client_name: '',
  client_street_address: '',
  company: '',
  home_elevation: null,
  latitude: null,
  longitude: null,
  company_verified_inspection: false,
  no_pictures: false,
  roof_type: ROOF_TYPES[0],
  gypsum_type: GYPSUM_TYPES[5],
  status: 'Draft',
  enclosed: ENCLOSURE_TYPES[0],
  exposure_category: null,
  notes: '',
  needs_structural_letter: false,
  needs_structural_stamp: false,
  needs_electrical_stamp: false,
  needs_ground_mount_letter: false,
  needs_cad_packet: false,
  needs_post_install_letter: false,
  needs_wall_mount_battery: false,
  needs_load_calculations: false,
  needs_non_pv_design: false,
  needs_shade_report: false,
  job_files_link: '',
  client_zip_code: '',
  arrays: []
};

export const CAD_FIELD_NAMES = {
  general_cad_fields: [
    'needs_structural_cad',
    'needs_ground_mount',
    'needs_battery_info',
    'needs_mci',
    'main_breaker_derate',
    'module_type',
    'custom_module_type',
    'module_count',
    'inverter_type',
    'custom_inverter_type',
    'inverter_count',
    'mpu_required',
    'msp_rating',
    // TODO: Add new fields here!!! like new_msp_rating, string_count, etc
    'msp_interior',
    'breaker_rating',
    'interconnection',
    'cad_notes',
    'contractor_license_num',
    'utility',
    'custom_utility',
    'production_meter_location'
  ],
  optimizer_fields: [
    'optimizer_type',
    'custom_optimizer_type',
    'optimizer_count'
  ],
  mci_fields: ['mci', 'mci_count'],
  mpu_required_fields: ['mpu_model_number'],
  battery_cad_fields: [
    'battery_type',
    'custom_battery_type',
    'battery_count',
    'battery_backup'
  ],
  structural_cad_fields: [
    'roof_material',
    'racking_type',
    'custom_racking_type',
    'connection_type',
    'custom_connection_type',
    'roof_pitch',
    'beam_shape',
    'custom_beam_shape',
    'beam_spacing'
  ],
  ground_mount_fields: [
    'racking_pitch',
    'preferred_racking_manufacturer',
    'foundation_type'
  ]
};

export const DEFAULT_CAD_INTAKE_DATA = {
  needs_structural_cad: true,
  needs_ground_mount: false,
  needs_battery_info: false,
  needs_optimizer: false,
  needs_mci: false,
  main_breaker_derate: false,
  roof_material: '',
  roof_pitch: '',
  racking_type: '',
  custom_racking_type: null,
  connection_type: '',
  custom_connection_type: null,
  module_type: '',
  custom_module_type: null,
  module_count: '',
  inverter_type: '',
  custom_inverter_type: null,
  inverter_count: '',
  optimizer_type: '',
  custom_optimizer_type: null,
  optimizer_count: '',
  battery_type: '',
  custom_battery_type: null,
  mci: '',
  mci_count: '',
  battery_count: '',
  battery_backup: '',
  mpu_required: false,
  mpu_model_number: '',
  msp_rating: '',
  msp_interior: false,
  breaker_rating: '',
  new_msp_rating: '',
  new_mb_rating: '',
  subpanel_routed: false,
  subpanel_rating: '',
  subpanel_ocpd_rating: '',
  interconnection: '',
  existing_solar: false,
  existing_interconnection: '',
  existing_msp_rating: '',
  existing_breaker_rating: '',
  existing_module_type: null,
  existing_module_count: '',
  existing_inverter_type: null,
  existing_inverter_count: '',
  racking_pitch: '',
  preferred_racking_manufacturer: '',
  cad_notes: '',
  contractor_license_num: '',
  utility: '',
  custom_utility: null,
  production_meter_location: '',
  foundation_type: '',
  beam_shape: '',
  custom_beam_shape: null,
  beam_spacing: 24
};

export const CAD_GENERATION_STATUS_META = {
  COLORS: {
    '': 'primary',
    'In Progress': 'info',
    Completed: 'success',
    Failed: 'error'
  },
  LABELS: {
    '': 'Not Completed',
    'In Progress': 'In Progress',
    Completed: 'Completed',
    Failed: 'Failed'
  }
};

export const SQFT_PER_ACRE = 43560;

export const CANADA_PROVINCES = [
  'Alberta',
  'Nova Scotia',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Northwest Territories',
  'Nova Scotia',
  'Nunavut',
  'Ontario',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
  'Yukon'
];
