import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router';

import { getTokens } from '../../shared/utils/helpers';

const RequireAuthenticated = ({ children }) => {
  const location = useLocation();

  return getTokens().accessToken ? (
    children
  ) : (
    <Navigate
      to="/login"
      state={{
        fromAuthRoute: true,
        from: location.pathname
      }}
    />
  );
};

RequireAuthenticated.propTypes = {
  children: PropTypes.node.isRequired
};

export default RequireAuthenticated;
