import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router';

import { getTokens } from '../../shared/utils/helpers';

const RequireUnauthenticated = ({ children }) => {
  return getTokens().accessToken ? <Navigate to="/jobs" /> : children;
};

RequireUnauthenticated.propTypes = {
  children: PropTypes.node.isRequired
};

export default RequireUnauthenticated;
